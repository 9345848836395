import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 1024px) {
    flex-direction: row;
    width: 1024px;
  }
  @media (max-width: 500px) {
    width: 400px;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  @media (max-width: 500px) {
    width: 100px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  border-radius: 5%;
  width: 200px;
  @media (max-width: 500px) {
    width: 150px;
  }
  @media (min-width: 600px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledImgMed = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 20%;
  width: 200px;
  @media (min-width: 600px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  @media (max-width: 500px) {
    width: 100px;
  }
  transition: width 0.5s;
`;

export const StyledImgSmall = styled.img`
  box-shadow: 20px 20px 20px 20px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 5%;
  width: 100px;
  @media (min-width: 600px) {
    width: 125px;
  }
  @media (min-width: 1000px) {
    width: 150px;
  }
  @media (max-width: 500px) {
    width: 100px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--tertiary);
  text-decoration: none;
`;

export const StyledLi = styled.li`
  content: content:”\203A”;
  color: var(--fourthandry);
  display: inline-block;
  width: 1em;
  margin-left: -1em;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mintBaller(blockchain.account, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 20) {
      newMintAmount = 20;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }    
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{backgroundColor: "var(--primary)" }}  
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/background_test8.png" : null}              
      >
        <s.TextTitleMobile
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
                color: "var(--primary)",
                backgroundColor: "var(--primary-text)",
                fontFamily: "Helvetica"
              }}
            >You are currently using a Mobile Device - Please change to Desktop View and rotate to landscape</s.TextTitleMobile>
        <s.SpacerSuperLarge />
        <s.SpacerSuperLarge />
        <s.SpacerSuperLarge />

        <a href={CONFIG.MARKETPLACE_LINK}>
          <StyledLogo alt={"logo"} src={"/config/images/BBC_Logo.gif"}  style={{ transform: "scale(3)" }}/>
        </a>
        <s.SpacerSuperLarge />
        <s.SpacerSuperLarge />

        <s.SpacerSmall />
        <s.SpacerSuperLarge />
        <ResponsiveWrapper flex={1} style={{ padding: "0px 0px 0px 30px", width: "100%" }} test >     
        <s.Container
            flex={1}
            jc={"left"}
            ai={"right"}></s.Container>     
          <s.ContainerMint
            flex={2}
            jc={"left"}
            ai={"center"}            
            style={{
              backgroundColor: "var(--accent)",
              padding: "0px 0px 0px 40px",
              border: "1px dashed var(--fourthandry)",
              borderRadius: 0,              
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
              
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 45,
                fontWeight: "bold",
                color: "var(--accent-text)",
                fontFamily: "Helvetica"
              }}
            >Public sale is now open</s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 45,
                fontWeight: "bold",
                color: "var(--accent-text)",
                fontFamily: "Helvetica",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
                fontFamily: "Helvetica",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <span>
              <s.SpacerSmall />
            </span>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "Helvetica"}}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "Helvetica" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "Helvetica" }}
                >
                 Presale: 1 {CONFIG.SYMBOL} costs {CONFIG.PRESALE_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "Helvetica" }}
                >
                 Public sale: 1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "Helvetica" }}
                >
                  Excluding gas fees.
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        fontFamily: "Helvetica",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                            fontFamily: "Helvetica",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)", fontFamily: "Helvetica",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)", fontFamily: "Helvetica",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.ContainerMint>
          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}></s.Container>   
          <s.SpacerLarge />         
        </ResponsiveWrapper>
        <s.SpacerSuperLarge />
        <s.Container jc={"right"} ai={"right"} style={{ width: "50%" }}>
          <s.TextTitle 
            style={{
              textAlign: "right",
              color: "var(--primary-text)",
              fontFamily: "Vladimir Script",
              fontSize: 72,
            }}
          >
          The Ballers
          <hr></hr>
          </s.TextTitle>
          <s.SpacerSuperLarge />
          <s.TextDescription
            style={{
              textAlign: "justify",
              color: "var(--primary-text)",
              fontFamily: "Helvetica",
            }}
          >
            A unique collection of Ballers that inspire others to do baller things, Billionaire Baller NFTs are made up with 
            over 70+ awesome traits including hats, hat symbols, chains, eyes, glasses, bodies and backgrounds. With some traits 
            having rarity between 1% and 2%. Each Baller is a unique non fungible token (NFT) on the Ethereum Blockchain.
            </s.TextDescription><s.SpacerLarge />
            <s.TextDescription
            style={{
              textAlign: "justify",
              color: "var(--primary-text)",
              fontFamily: "Helvetica",
            }}            >

            Owning a Billionaire Baller NFT is two-fold, it grants you complete copyright of the artwork as well as acting as a token
            to membership in the Billionaire Ballers Club. Membership to the club enables participation in various collaborative projects as well
            as any projects/events driven by the community. 
            </s.TextDescription>
            <s.SpacerLarge />
            <s.TextDescription
            style={{
              textAlign: "justify",
              color: "var(--primary-text)",
              fontFamily: "Helvetica",
            }}            >

            The Billionaire Ballers Club NFT presales launches on the 31 January 2022 at 2:00pm GMT
          </s.TextDescription>
          <s.SpacerSuperLarge />  
                   
          <s.TextTitle 
            style={{
              textAlign: "left",
              fontFamily: "Vladimir Script",
              fontSize: 72,
            }}
          >
          The Founders
          <hr></hr>
          </s.TextTitle>
          <s.SpacerSuperLarge /> 
        
          <s.Container jc={"center"} ai={"center"} style={{ width: "100%" }}>        
            <ResponsiveWrapper flex={1} style={{ padding: 0 }} test>
              <s.ContainerFounders flex={1} jc={"center"} ai={"center"}>
                <StyledImgMed
                  alt={"example"}
                  src={"/config/images/Founder1.png"}
                  style={{ transform: "scale(0.9)" }}
                />
              </s.ContainerFounders>
              <s.ContainerFounders flex={1} jc={"center"} ai={"left"}>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                    fontFamily: "Helvetica",
                  }}
                  >
                  Axel The Great
                  </s.TextDescription>
                  <s.SpacerSmall />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--primary-text)",
                      fontFamily: "Helvetica",
                    }}>

                  This guy is the blockchain enthusiast, the guy who never sold, the real crypto junkie. A real Baller, the Crypto Baller.
                </s.TextDescription>
              </s.ContainerFounders>
            </ResponsiveWrapper>
          </s.Container>
          <s.Container jc={"center"} ai={"center"} style={{ width: "100%" }}>        
            <ResponsiveWrapper flex={1} style={{ padding: 0 }} test>
            <s.ContainerFounders flex={1} jc={"center"} ai={"left"}>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                    fontFamily: "Helvetica",
                  }}>

                  Sir Tinkalot
                  </s.TextDescription>
                  <s.SpacerSmall />
                  <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                    fontFamily: "Helvetica",
                  }}>
                    Having been in crypto for several years, this is the first time he got to express himself in the form of artwork. 
                    We think its fantastic. He's the All-Rounder Baller.
                  </s.TextDescription>
              </s.ContainerFounders>
              <s.ContainerFounders flex={1} jc={"center"} ai={"center"}>
                <StyledImgMed
                  alt={"example"}
                  src={"/config/images/Founder2.png"}
                  style={{ transform: "scale(0.9)" }}
                />
              </s.ContainerFounders>
              
            </ResponsiveWrapper>
          </s.Container>
          <s.Container jc={"center"} ai={"center"} style={{ width: "100%" }}>        
            <ResponsiveWrapper flex={1} style={{ padding: 0 }} test>
              <s.ContainerFounders flex={1} jc={"center"} ai={"center"}>
                <StyledImgMed
                  alt={"example"}
                  src={"/config/images/Founder3.png"}
                  style={{ transform: "scale(0.9)" }}
                />
              </s.ContainerFounders>
              <s.ContainerFounders flex={1} jc={"center"} ai={"center"}>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                    fontFamily: "Helvetica",
                  }}>
                  Mr Frank
                </s.TextDescription>
                <s.SpacerSmall />
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                    fontFamily: "Helvetica",
                  }}>
                  They call him "The Manager", the one gets the others to perform miracles. The Original Baller.
                </s.TextDescription>
              </s.ContainerFounders>
            </ResponsiveWrapper>
          </s.Container>
          <s.SpacerSuperLarge />
          <s.TextTitle 
            style={{
              textAlign: "right",
              color: "var(--primary-text)",
              fontFamily: "Vladimir Script",
              fontSize: 72,
            }}
          >
          Road Map
          <hr></hr>
          </s.TextTitle>
          <s.SpacerSuperLarge />
          <s.TextSubTitle
              style={{
              textAlign: "left",
              fontFamily: "Georgia",
              padding: "10px 50px 10px 50px",
            }}
          >
             Phase 1 - Baller Conceptulization
          </s.TextSubTitle>
          <s.SpacerMedium />
          <s.TextDescription
            style={{
              textAlign: "justify",
              color: "var(--primary-text)",
              fontFamily: "Helvetica",
              padding: "10px 100px 10px 100px",

            }}>
            
            <li> The Design concept phase of the artwork </li>
            <li> Setting up the Team Collaboration </li>
            <li> The Framework preparation and design </li>    
                 
          </s.TextDescription>
          <s.SpacerSuperLarge />     
          <s.TextSubTitle
              style={{
              textAlign: "left",
              fontFamily: "Georgia",
              padding: "10px 50px 10px 50px",
            }}
          >
             Phase 2 - Making the Baller NFTs
          </s.TextSubTitle>
          <s.SpacerMedium />
          <s.TextDescription
            style={{
              textAlign: "justify",
              color: "var(--primary-text)",
              fontFamily: "Helvetica",
              padding: "10px 100px 10px 100px", 
            }}>

            <li> Formalizing the Project </li> 
            <li> Creating the NFT Collection  </li>
            <li> Website Launch </li>
                       
          </s.TextDescription>
          <s.SpacerSuperLarge />     
          <s.TextSubTitle
              style={{
              textAlign: "left",
              fontFamily: "Georgia",
              padding: "10px 50px 10px 50px",
            }}
          >
             Phase 3 - Everyone wants to be a Billionaire Baller
          </s.TextSubTitle>
          <s.SpacerMedium />
          <s.TextDescription
            style={{
              textAlign: "justify",
              color: "var(--primary-text)",
              fontFamily: "Helvetica",
              padding: "10px 100px 10px 100px", 
            }}>

            <li> Initiating the growth of the Club through Discord Channels and Twitter </li> 
            <li> Outlining the idea of creating an airdrop for early members  </li>
            <li> Collecting Rarity info on all the different traits </li>
                       
          </s.TextDescription>
          <s.SpacerSuperLarge />  
          <s.TextSubTitle
              style={{
              textAlign: "left",
              fontFamily: "Georgia",
              padding: "10px 50px 10px 50px",
            }}
          >
             Phase 4 - Opening the Ballers Mint
          </s.TextSubTitle>
          <s.SpacerMedium />
          <s.TextDescription
            style={{
              textAlign: "justify",
              color: "var(--primary-text)",
              fontFamily: "Helvetica",
              padding: "10px 100px 10px 100px", 
            }}>

            <li> Launch of the Pre Sale </li> 
            <li> Launch of the Public Sale  </li>
            <li> Possibly looking at intergrating with some L1's to lower the gas fees </li>
                       
          </s.TextDescription>
          <s.SpacerSuperLarge /> 
          <s.TextSubTitle
              style={{
              textAlign: "left",
              fontFamily: "Georgia",
              padding: "10px 50px 10px 50px",
            }}
          >
             Phase 5 - Intergrating with a Baller Marketplace
          </s.TextSubTitle>
          <s.SpacerMedium />
          <s.TextDescription
            style={{
              textAlign: "justify",
              color: "var(--primary-text)",
              fontFamily: "Helvetica",
              padding: "10px 100px 10px 100px", 
            }}>

            <li> Integrating with Opensea </li> 
            <li> Verifying our Baller Collection  </li>
            <li> Additions to the Billionaire Baller Club Collaboration Ideas </li>
            
                       
          </s.TextDescription>
          <s.SpacerSuperLarge /> 
          <s.TextSubTitle
              style={{
              textAlign: "left",
              fontFamily: "Georgia",
              padding: "10px 50px 10px 50px",
            }}
          >
             Phase 6 - Baller Collaborations and Future Projects
          </s.TextSubTitle>
          <s.SpacerMedium />
          <s.TextDescription
            style={{
              textAlign: "justify",
              color: "var(--primary-text)",
              fontFamily: "Helvetica",
              padding: "10px 100px 10px 100px", 
            }}>

            <li> Rarity Explorer </li>
            <li> Formalize a Collaboration Project </li> 
            <li> Formalize a Community Driven Project  </li>
            <li> Complete Integration to a L1 Marketplace </li>
                       
          </s.TextDescription>
          
          <s.SpacerSuperLarge />
          <s.Container jc={"left"} ai={"left"} style={{ width: "100%" }}>
            <s.TextTitle 
              style={{
                textAlign: "left",
                color: "var(--primary-text)",
                fontFamily: "Vladimir Script",
                fontSize: 72,
              }}
            >
            Join The Club
            <hr></hr>
            </s.TextTitle> 
          </s.Container>
          <s.SpacerSuperLarge />          
          <s.ContainerSocials jc={"center"} ai={"center"} style={{ width: "100%" }}>        
            <ResponsiveWrapper flex={1} style={{ padding: 0 }} test>
            
              <s.Container flex={1} jc={"center"} ai={"center"}>
                <StyledImgSmall
                    alt={"example"}
                    src={"/config/images/twitter.png"}
                    style={{ transform: "scale(1)" }} 
                  />                             
                <s.SpacerSmall />
                <s.SpacerSmall /> 
                <StyledLink target={"_blank"} href="http://twitter.com/BilBallersClub">
                  TWITTER
                </StyledLink>                               
              </s.Container>
              
              <s.Container flex={1} jc={"center"} ai={"center"}>
                <StyledImgSmall
                  alt={"example"}
                  src={"/config/images/discord.png"}
                  style={{ transform: "scale(1)" }}
                  href={"https://discord.gg/qU74uer5sw"}
                />
                <s.SpacerSmall /> 
                <s.SpacerSmall /> 
                <StyledLink target={"_blank"} href="https://discord.gg/qU74uer5sw">
                  DISCORD
                </StyledLink>
                                 
              </s.Container> 
            </ResponsiveWrapper>
          </s.ContainerSocials>
          <s.SpacerSuperLarge />
          <s.SpacerSuperLarge />         
          

          <s.SpacerSuperLarge />
          <s.SpacerSuperLarge />
          <s.SpacerSuperLarge />
        
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
              fontStyle: "Italic"
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
              fontStyle: "Italic"
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
